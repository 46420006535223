import {  Row, Col } from 'react-bootstrap';


function EditCategoriesInputOutput() {
    return (
        <Row>
            <Col>
                <></>
            </Col>
        </Row>
    )
}

export default EditCategoriesInputOutput;