const apiUrl = process.env.REACT_APP_API_URL;


export const getInputCategorires = async() => {
    const token = sessionStorage.getItem('token');
    const url =  `${apiUrl}/api/generalQueries/getInputCategorires`;       
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
         },
    };
    try {
       const response = await fetch(url, requestOptions);
       return await response.json(); 
    } catch (error) {
        console.log(error)
        return [];
    }
}

export const getCurrencies = async() => {
    const token = sessionStorage.getItem('token');
    const url =  `${apiUrl}/api/generalQueries/getCurrencies`;       
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
         },
    };
    try {
       const response = await fetch(url, requestOptions);
       return await response.json(); 
    } catch (error) {
        console.log(error)
        return [];
    }
}

export const getOutputCategories = async() => {
    const token = sessionStorage.getItem('token');
    const url =  `${apiUrl}/api/generalQueries/getOutputCategories`;       
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
         },
    };
    try {
       const response = await fetch(url, requestOptions);
       return await response.json(); 
    } catch (error) {
        console.log(error)
        return [];
    }
}