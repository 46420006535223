import { useEffect, useState } from 'react';
import { Card, Row, Col, Button ,ButtonGroup, ToggleButton, Form, Spinner } from 'react-bootstrap';
import { getInputCategorires } from '../../services/generalQueries';
import { addTransactionInput } from '../../services/transactions';
import { getFormattedDate } from '../../utils/dateUtils';


function Input({ board_id, reload, setReload }) {
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [radioValue, setRadioValue] = useState(1);
  const [inputValue, setInputValue] = useState('');
  const [date, setDate] = useState('');
  const [comments, setComments] = useState('');
  const handleAdd = () => {
    if(date && board_id && radioValue && inputValue)    
    {
        setIsSaving(true)
        addTransactionInput(date, board_id, radioValue, inputValue, comments).then((result)=> {
            setReload(!reload);
            setIsSaving(false);
            setInputValue('');
            setComments('');
        }).catch(() => {
          setIsSaving(false);
          setInputValue('');
          setComments('');
        })
    }    
  }
  const handleInputChange = (e) => {
    setInputValue(e.target.value); // Actualiza el estado con el valor ingresado
  };
  const handleCommentsChange = (event) => {
    setComments(event.target.value);
  };
  useEffect(() => {
    getInputCategorires().then((categoriesList) => {
      setCategories(categoriesList);
      setIsLoading(false);
    });

    setDate(getFormattedDate());
  }, []);

  return (
    <Card border="success">
      <Card.Body>
        <Row>
          <Col xl={12}>
            <ButtonGroup className="mb-2">
              {categories.map((category) => (
                <ToggleButton
                  key={category.id}
                  id={`radio-${category.id}`}
                  type="radio"
                  variant="outline-info"
                  name="radio"
                  value={category.id}
                  checked={radioValue === category.id}
                  onChange={(e) => setRadioValue(Number(e.currentTarget.value))} // Convertir a número
                >
                  {category.name}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </Col>
        </Row>    
        <Row className='mt-3 mb-3'>
          <Col xs={6} className="text-center" >
                <Form.Group controlId="formDateTime">
                <Form.Control
                      type="date"
                      value={date}                 
                      onChange={(e) => setDate(e.target.value)}
                      style={{ width: '150px' }} 
                      className="mx-auto" 
              
                />
                </Form.Group>
          </Col>
          <Col xs={6} className='text-center' >
            <Form.Group controlId="formBasicText">
                <Form.Control
                type="text"
                value={inputValue} // Valor del input manejado por el estado
                onChange={handleInputChange} // Función que maneja el cambio en el input
                style={{ width: '150px' }} 
                className="mx-auto" // Centra el texto dentro del input
                placeholder='$ Ingreso'
                autoFocus
                />
            </Form.Group>
          </Col>
        </Row>     
        <Row>
          <Col xl={12} >
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Control 
                as="textarea" 
                rows={1} 
                value={comments} 
                placeholder="Comentarios"
                onChange={handleCommentsChange} 
              />
            </Form.Group>
          </Col>
      </Row>
        <Row className='mt-4'>
          <Col className='text-center'>
                <Button variant='success' onClick={handleAdd} >
                {isSaving ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    /> Agregando...
                  </>
                ) : (
                  'Agregar +'
                )}
                
                  
                </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default Input;