const apiUrl = process.env.REACT_APP_API_URL;

export const addBoard = async(name, currency_id) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const token = sessionStorage.getItem('token');
    const url =  `${apiUrl}/api/boards/addBoard`;       
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
         },
        body: JSON.stringify({
            name,
            currency_id
        })
    };
    try {
       const response = await fetch(url, requestOptions);
       return await response.json(); 
    } catch (error) {
        return { result: false }
    }
}

export const getActiveBoardsByUserId = async() => {
    const token = sessionStorage.getItem('token');
    const url =  `${apiUrl}/api/boards/getActiveBoardsByUserId/`;       
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
         },
    };
    try {
       const response = await fetch(url, requestOptions);
       return await response.json(); 
    } catch (error) {
        console.log(error)
        return [];
    }
}

export const getBoardById = async(id) => {
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url =  `${apiUrl}/api/boards/getBoardById/${id}`;       
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
         },
    };
    try {
       const response = await fetch(url, requestOptions);
       return await response.json(); 
    } catch (error) {
        console.log(error)
        return [];
    }
}

export const updateBoard = async(data) => {
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url =  `${apiUrl}/api/boards/updateBoard`;       
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
         },
         body: JSON.stringify(data) 
    };
    try {
       const response = await fetch(url, requestOptions);
       return await response.json(); 
    } catch (error) {
        console.log(error)
        return [];
    }
}


