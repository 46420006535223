import { ListGroup, Row, Col, Badge, Card } from 'react-bootstrap';
import TransactionModal from './TransactionModal';
import { useEffect, useState } from 'react';
import { getFormattedAmount_moneyBySessionSetting } from '../../utils/currenciesUtils';


function TransactionsList({ data, reload, setReload, enableEdit  = true }) {
  const [showModal, setShowModal] = useState(false);
  const [transaction_id, setTransaction_id] = useState(0);
  const handleCloseModal = () => {
    setShowModal(false);
    setReload(!reload);
  }
  const handleEditTransaction = (id) => {
    setShowModal(true)
    setTransaction_id(id);
  }
  return (
    <>
        
          
        <h3>{ enableEdit ? "Últimos movimientos" : "Movimientos" }</h3>                 
        <ListGroup className='scrollable-row' variant="flush">
          { 
          data && data.length > 0 ? 
          data.map((item) => (
              <ListGroup.Item onClick={() => handleEditTransaction(item.id)} >
                <Row>
                  <Col xs={4}>
                  <Badge bg={item.is_input ? "success" :"warning"}>{item.is_input ? "+": ""}{item.amount_money ?  getFormattedAmount_moneyBySessionSetting(item.amount_money) : "$0"}</Badge>
                  </Col>
                  <Col xs={8}>
                    <Row>
                      <Col>
                      {

                        item.transaction_output_category ?
                        <i class={item.transaction_output_category.icon}></i> :
                        null
                      }&nbsp;
                      {
                        item.is_input ?
                        item.transactions_movement_type.name :
                        item.transaction_output_category.name
                      }
                      {
                        item.comments ?
                        ` (${item.comments})` :
                        null
                      }
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                      <Badge bg="secondary">{item.formattedDate}</Badge>
                      </Col>
                    </Row>
            

                  </Col>
                </Row>                             
              </ListGroup.Item> 
          )) :
          null
          }
    </ListGroup>
    <TransactionModal show={showModal} handleClose={handleCloseModal} id={transaction_id} enableEdit={enableEdit} />
    </>
  );
}

export default TransactionsList;