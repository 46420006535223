import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import TransactionModalDisplay from './TransactionModalDisplay';
import TransactionModalEdit from './TransactionModalEdit';
import { updateTransactionInput, updateTransactionOutput, deleteTransaction } from '../../services/transactions';
import Loading from '../Loading';
import { ToastContainer, toast } from 'react-toastify';


function TransactionModal({ show, handleClose, id, enableEdit = true }) {
  const [isBeingEdited, setIsBeingEdited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const deleteThisTransaction = () => {
    deleteTransaction(id).then(() => {
      handleClose();
    })
  }
  const editThisTransaction = () => {
    setIsBeingEdited(true);
  };
  const updateTransaction = () =>{
    if(data.is_input)
    {      
      setIsLoading(true)
      updateTransactionInput(data.id, data.formattedDateForInput, data.movement_type_id, data.formattedAmount_money, data.comments).then((updateResult) => {
        setIsLoading(false)
        toast.success(`Registro actualizado correctamente`);
      }).catch(() => {
        setIsLoading(false)
        toast.warning(`!Algo salió mal!`)
      })
    }
    else
    {
      setIsLoading(true)
      updateTransactionOutput(data.id, data.formattedDateForInput, data.movement_type_id, data.formattedAmount_money, data.comments, data.output_category_id).then((updateResult) => {
        setIsLoading(false)
        toast.success(`Registro actualizado correctamente`);
      }).catch(() => {
        setIsLoading(false)
        toast.warning(`!Algo salió mal!`)
      })
    }        
  }
  useEffect(() => {
    if (!show) {
      setIsBeingEdited(false);
    }
  }, [show, isLoading]);

  return (
    <>
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Movimiento</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          isLoading ?
          <Loading/> :
          isBeingEdited ?
            <TransactionModalEdit id={id} data={data} setData={setData} /> :
            <TransactionModalDisplay id={id} />
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        {
          enableEdit ?
          isBeingEdited ?
          <Button variant="success" onClick={updateTransaction} >
          Guardar
          </Button> 
            :
            <>
            <Button variant="danger" onClick={deleteThisTransaction} >
            <i class="bi bi-trash"></i>
            </Button> 
            <Button variant="warning" onClick={editThisTransaction} >
            Editar
            </Button> 
            </>
            :
            null
        }
      </Modal.Footer>
    </Modal>    
    <ToastContainer />
    </>
  );
}

export default TransactionModal;