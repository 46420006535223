const apiUrl = process.env.REACT_APP_API_URL;


export const getReport1 = async(startDate, endDate, board_id) => {
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url =  `${apiUrl}/api/reports/getReport1`;       
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
         },
         body: JSON.stringify({
            startDate, endDate, board_id
        })
    };
    try {
       const response = await fetch(url, requestOptions);
       return await response.json(); 
    } catch (error) {
        console.log(error)
        return [];
    }
}

export const getTransactionsByStartAndEndDate = async(startDate, endDate, board_id) => {
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url =  `${apiUrl}/api/reports/getTransactionsByStartAndEndDate`;       
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
         },
         body: JSON.stringify({
            startDate, endDate, board_id
        })
    };
    try {
       const response = await fetch(url, requestOptions);
       return await response.json(); 
    } catch (error) {
        console.log(error)
        return [];
    }
}




export const getSomeWeeks = async(weeksQty) => {
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url =  `${apiUrl}/api/reports/getSomeWeeks/${weeksQty}`;       
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
         }
    };
    try {
       const response = await fetch(url, requestOptions);
       return await response.json(); 
    } catch (error) {
        console.log(error)
        return [];
    }
}

export const getCurrentWeekInputOutput = async(board_id, week) => {
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url =  `${apiUrl}/api/reports/getCurrentWeekInputOutput`;       
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
         },
         body: JSON.stringify({
            board_id,
            week
        })
    };
    try {
       const response = await fetch(url, requestOptions);
       return await response.json(); 
    } catch (error) {
        console.log(error)
        return [];
    }
}

