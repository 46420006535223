import { useEffect, useState } from "react";
import { Row, Col, Button, Modal, ListGroup, Badge } from 'react-bootstrap';
import { getCurrentWeekInputOutput  } from '../../services/reports';
import { getFormattedAmount_moneyBySessionSetting } from '../../utils/currenciesUtils';
import Loading from '../../components/Loading';


function TransactionsByWeekModal({ showTransactions, setShowTransactions, selectedWeek }){
    const  board_id = sessionStorage.getItem('board_id');
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        setIsLoading(true);
        setTimeout(() => {
            getCurrentWeekInputOutput(board_id, parseInt(selectedWeek)).then((result) => {
                setData(result);     
                setIsLoading(false);       
            }).catch((error) => {            
                setIsLoading(false);
            });
        }, 1000); // Simula un retraso de 1 segundo
    }, [selectedWeek]);
    
    return (<>
      <Modal
        size="lg"
        show={showTransactions}
        onHide={() => setShowTransactions(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        {
            isLoading ? 
            <Loading /> :
            <>
            <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Semana: {data.startDate} - {data.endDate}            
            </Modal.Title>
          </Modal.Header>
    
              <Modal.Body>
                  <Row>
                      <Col>
                          <h3 className='text-success'>Ingresos: { getFormattedAmount_moneyBySessionSetting(data.totalAmountIncome) }</h3>              
                          <h3 className='text-danger'>Gastos: { getFormattedAmount_moneyBySessionSetting(data.totalAmountExpenses) }</h3>        
                      </Col>
                  </Row>
                  <Row className="mt-4">
                      <Col>
                      
                          <h3>
                          { 
                              data.data && data.data.length > 0 ? 
                              "Movimientos" :
                              "Sin movimientos"
                          }                        
                          </h3>
                      </Col>
                  </Row>
                  <Row>
                      <Col>
                      <ListGroup className='scrollable-row' variant="flush">
              { 
              data.data && data.data.length > 0 ? 
              data.data.map((item) => (
                  <ListGroup.Item  >
                      <Row>
                      <Col xs={4}>
                      <Badge bg={item.is_input ? "success" :"warning"}>{item.is_input ? "+": ""}{item.amount_money ?  getFormattedAmount_moneyBySessionSetting(item.amount_money) : "$0"}</Badge>
                      </Col>
                      <Col xs={8}>
                          <Row>
                          <Col>
                          {
  
                              item.transaction_output_category ?
                              <i class={item.transaction_output_category.icon}></i> :
                              null
                          }&nbsp;
                          {
                              item.is_input ?
                              item.transactions_movement_type.name :
                              item.transaction_output_category.name
                          }
                          {
                              item.comments ?
                              ` (${item.comments})` :
                              null
                          }
                                  </Col>
                                  </Row>
                                  <Row>
                                  <Col>
                                  <Badge bg="secondary">{item.formattedDate}</Badge>
                                  </Col>
                                  </Row>
                          
  
                              </Col>
                              </Row>                             
                          </ListGroup.Item> 
                      )) :
                      null
                      }
                  </ListGroup>
                      </Col>
                  </Row>
          </Modal.Body> 
          </>
        }
          
      </Modal>
    </>)
}

export default TransactionsByWeekModal;