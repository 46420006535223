const apiUrl = process.env.REACT_APP_API_URL;


export const updateTransactionInput = async(id, date, movement_type_id, amount_money_string, comments) => {    
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url =  `${apiUrl}/api/transactions/updateTransactionInput`;       
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
         },
        body: JSON.stringify({
            id, date, movement_type_id, amount_money_string, comments,
        })
    };
    try {
       const response = await fetch(url, requestOptions);
       return await response.json(); 
    } catch (error) {
        return { result: false }
    }
}



export const updateTransactionOutput = async(id, date, movement_type_id, amount_money_string, comments, output_category_id) => {    
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url =  `${apiUrl}/api/transactions/updateTransactionOutput`;       
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
         },
        body: JSON.stringify({
            id, date, movement_type_id, amount_money_string, comments, output_category_id
        })
    };
    try {
       const response = await fetch(url, requestOptions);
       return await response.json(); 
    } catch (error) {
        return { result: false }
    }
}




export const addTransactionInput = async(date, board_id, movement_type_id, amount_money_string, comments) => {    
    const token = sessionStorage.getItem('token');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url =  `${apiUrl}/api/transactions/addTransactionInput`;       
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
         },
        body: JSON.stringify({
            date, board_id, movement_type_id, amount_money_string, comments,
        })
    };
    try {
       const response = await fetch(url, requestOptions);
       return await response.json(); 
    } catch (error) {
        return { result: false }
    }
}

export const addTransactionOutput = async(date, board_id, movement_type_id, amount_money_string, output_category_id, comments) => {  
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const token = sessionStorage.getItem('token');
    const url =  `${apiUrl}/api/transactions/addTransactionOutput`;       
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
         },
        body: JSON.stringify({
            date, board_id, movement_type_id, amount_money_string, output_category_id, comments
        })
    };
    try {
       const response = await fetch(url, requestOptions);
       return await response.json(); 
    } catch (error) {
        return { result: false }
    }
}




export const getTotalAmountByBoard_id = async(id) => {
    const token = sessionStorage.getItem('token');
    const url =  `${apiUrl}/api/transactions/getTotalAmountByBoard_id/${id}`;       
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
         },
    };
    try {
       const response = await fetch(url, requestOptions);
       return await response.json(); 
    } catch (error) {
        console.log(error)
        return [];
    }
}

export const getLastTransactions = async(id) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const token = sessionStorage.getItem('token');
    const url =  `${apiUrl}/api/transactions/getLastTransactions/${id}`;       
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
         },
    };
    try {
       const response = await fetch(url, requestOptions);
       return await response.json(); 
    } catch (error) {
        console.log(error)
        return [];
    }
}

export const getTransactionById = async(id) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const token = sessionStorage.getItem('token');
    const url =  `${apiUrl}/api/transactions/getTransactionById/${id}`;       
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Time-Zone': userTimeZone
         },
    };
    try {
       const response = await fetch(url, requestOptions);
       return await response.json(); 
    } catch (error) {
        console.log(error)
        return [];
    }
}

export const deleteTransaction = async(id) => {
    const token = sessionStorage.getItem('token');
    const url =  `${apiUrl}/api/transactions/deleteTransaction/${id}`;       
    const requestOptions = {
        method: 'DELETE',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
         },
    };
    try {
       const response = await fetch(url, requestOptions);
       return await response.json(); 
    } catch (error) {
        console.log(error)
        return [];
    }
}