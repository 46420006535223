import React, { useState, useEffect } from 'react';
import { Container, Form, Button, InputGroup, Spinner, Row, Col } from 'react-bootstrap';
import './Login.css'; 
import { requestPasswordReset } from '../services/auth'
function PasswordResetRequest() {
  const [showMsg, setShowMsg] = useState(false);
  const [email, setEmail] = useState('');
  const [validationMsg, setValidationMsg] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmail(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(email)
    {
        setValidationMsg('')
        setIsLoading(true)
        requestPasswordReset(email).then((resetResult) => {
            setIsLoading(false)
            setShowMsg(true);
        }) 
    }
    else
    {
        setValidationMsg('Es necesario ingresar tu correo electrónico')
    }
  };

  return (
    <Container fluid className="mt-5 justify-content-center align-items-center min-vh-100">
      <div className="login-container">
        <h3 className="text-center mb-4">GastoHormiga.mx</h3>
        {
            showMsg ?
            <>
            <Row>
                <Col>
                    <h4>Gracias por solicitar el reinicio de contraseña. Recibirás un correo con instrucciones para restablecer tu contraseña</h4>
                    <b>Si no encuentra nuestro correo en su bandeja de entrada, por favor revise su carpeta de correo no deseado, spam o junk.</b>
                </Col>
            </Row>
             <Row className='mt-4 mb-4'>
             <Col className='text-left'>
                 <a href='/' >Iniciar sesión</a>
             </Col>
           </Row>
           </>
            :
<Form onSubmit={handleSubmit}>
          <Form.Group controlId="formUsername" className="mb-3">
            <Form.Label>Correo electrónico</Form.Label>
            <InputGroup>
              <InputGroup.Text>
                <i className="bi bi-person-fill"></i>
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Ingresa tu Correo electrónico"
                name="username"
                value={email}
                onChange={handleChange}
              />
            </InputGroup>
            <div className="error-message">{validationMsg}</div>
          </Form.Group>
          <Button variant="primary" type="submit" className="w-100" disabled={isLoading}>
            {isLoading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                /> Cargando...
              </>
            ) : (
              'Recuperar contraseña'
            )}
          </Button>
        </Form>
        }
        

      </div>
    </Container>
  );
}

export default PasswordResetRequest;