import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Form, Button, ListGroup, Badge } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { getReport1, getTransactionsByStartAndEndDate } from '../services/reports';
import { getFormattedAmount_moneyBySessionSetting } from '../utils/currenciesUtils';
import { getFormattedDate, getFormattedDateMinus7Days } from '../utils/dateUtils';
import DoughnutChart from '../components/Reports/DoughnutChart';
import TransactionsList from '../components/Transactions/TransactionsList';
import Loading from '../components/Loading';

function ReportByDates() {
    const navigate = useNavigate();
    const  id = sessionStorage.getItem('board_id');
    const [reload, setReload] = useState(false)
    const [startDate, setStartDate] = useState(getFormattedDateMinus7Days());
    const [endDate, setEndDate] = useState(getFormattedDate());
    const [data, setData] = useState({});
    const [transactions, setTransactions] = useState([]);
    const [isLoadingTransactions, setIsLoadingTransactions] = useState(true);
    const handleBack = () =>{
        navigate(-1)
      }
    const handleReloadReport = () => {
        getReport1(startDate, endDate, id).then((result) => {
            console.log(result)
            setData(result);
        });
    }
    useEffect(() => {      
      getReport1(startDate, endDate, id).then((result) => {
          setData(result);
      });
      getTransactionsByStartAndEndDate(startDate, endDate, id).then((list) => {
        setTransactions(list);
        setIsLoadingTransactions(false);
      });
    }, [startDate, endDate, reload]); 
    return (
        <>
        <Row className='mt-2'>
                <Col xs={6} className='text-left'>
                    <Button className='m-1' size='sm' variant="dark" onClick={handleBack} ><i class="bi bi-arrow-left"></i></Button>
                </Col>
                <Col xs={6} className='text-end'>
            
                </Col>
        </Row>
        <Card className='mt-3 '>
 
        <Card.Body>
        <Row className='mt-3 mb-3'>
  <Col xs={5} className="d-flex align-items-center justify-content-center">
    <Form.Group controlId="formDateTime">
      <Form.Label className="me-2">Fecha inicio</Form.Label>
      <Form.Control
        type="date"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
        style={{ width: '130px' }}
      />
    </Form.Group>
  </Col>
  <Col xs={5} className="d-flex align-items-center justify-content-center">
    <Form.Group controlId="formDateTime">
      <Form.Label className="me-2">Fecha final</Form.Label>
      <Form.Control
        type="date"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
        style={{ width: '130px' }}
      />
    </Form.Group>
  </Col>
  <Col xs={2} className="d-flex align-items-center justify-content-start">
    <Button variant='success' onClick={handleReloadReport}><i class="bi bi-arrow-clockwise"></i></Button>
  </Col>
</Row>
            <Row>
                <Col xl={12} >
                    <ListGroup variant="flush">
                        <ListGroup.Item>
                            <h2>Ingreso: <Badge bg="success">{getFormattedAmount_moneyBySessionSetting(data.totalAmountIncome)}</Badge></h2>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <h2>Gastos: <Badge bg="danger">{getFormattedAmount_moneyBySessionSetting(data.totalAmountExpenses)}</Badge></h2>                                 
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
            </Row>
            <Row >
                <Col xs={12} className="text-center" >
                    <DoughnutChart data={data.expensesGroupedByOutputCategory} />
                </Col>
            </Row>
            {
              isLoadingTransactions ? 
              <Loading /> :
              <Row className='mt-2' >
                  <Col xl={12}>
                      <TransactionsList data={transactions} reload={reload} setReload={setReload} enableEdit={false} />
                  </Col>
              </Row>
            }
        </Card.Body>
      </Card>
      </>
    )
}

export default ReportByDates;