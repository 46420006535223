import { Row, Col, Button, Card, Form, Spinner, Badge } from 'react-bootstrap';
import { useEffect, useState}  from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getBoardById, updateBoard } from '../../services/boards';
import { getCurrencies } from '../../services/generalQueries';
import Loading from '../Loading';
import { ToastContainer, toast } from 'react-toastify';



function EditBoard() {
    const navigate = useNavigate();
    const  id = sessionStorage.getItem('board_id');
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [currencies, setCurrencies] = useState([]);
    const handleBack = () =>{
        navigate(-1)
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setData({
          ...data,
          [name]: value
        });
      };
      const handleDropDownChange = (e) => {
        const { name, value } = e.target;
        setData({
          ...data,
          [name]: value
        });
      };
      const handleSwitchChange = (e) => {
        setData({
          ...data,
          is_active: e.target.checked // Actualiza el estado booleano basado en el switch
        });
      };
      const handleSaveChanges = () => {
        setIsSaving(true);
        updateBoard(data).then((requestResult) => {
            if(requestResult.result)
            {
                toast.success(`Cambios guardados correctamente`);
                setIsSaving(false);
            }
            else
            {
                toast.warning(`!Algo salio mal!`);
                setIsSaving(false);
            }
        })

      }
    useEffect(() => {
        getBoardById(id).then((boardData) => {
            setData(boardData);
            setIsLoading(false);
        });
        getCurrencies().then((list) => {
            setCurrencies(list);
        });
    }, [])
    return (
        <>
        <Row className='mt-2'>
            <Col xs={6} className='text-left'>
                <Button className='m-1' size='sm' variant="dark" onClick={handleBack} ><i class="bi bi-arrow-left"></i></Button>
            </Col>
            <Col xs={6} className='text-end'>
            </Col>
        </Row>
        {
            isLoading ? 
            <Loading /> :
            <Card className='mt-3' >
                <Card.Body>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Nombre de la cuenta</Form.Label>
                        <Form.Control 
                        type="text" 
                        name="name"
                        placeholder="Nombre de la cuenta" 
                        value={data.name}
                        onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formRole">
                            <Form.Label>Divisa:</Form.Label>
                            <Form.Control
                                as="select"
                                name="currency_id"
                                value={data.currency_id}
                                onChange={handleDropDownChange}
                            >
                                <option key={0} value={0}>Seleccionar</option>
                                {
                                currencies && currencies.length ?   
                                currencies.map((currency) => (
                                        <option key={currency.id} value={currency.id}>{currency.name_short} - {currency.name_long}</option>
                                ))
                                :
                                null
                                }
                            </Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formSwitch">
                        <Form.Check 
                        type="switch"
                        name='is_active'
                        label={data.is_active ? "Activo" : "Inactivo"}
                        checked={data.is_active}
                        onChange={handleSwitchChange}
                        />
                    </Form.Group>
                    {
                        data.updated_at_formatted ? 
                        <Row className='mb-3'>
                            <Col>
                                <Badge bg="secondary">{data.updated_at_formatted}</Badge>
                            </Col>
                        </Row> :
                        null
                    }
                   
                    <Row>
                        <Col className='text-center' >
                            <Button variant='success' onClick={handleSaveChanges} >
                               {isSaving ? (
                                <>
                                    <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    /> Guardando...
                                </>
                                ) : (
                                'Guardar cambios'
                                )}
                                </Button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        }
         <ToastContainer />
        </>
    )
}

export default EditBoard;