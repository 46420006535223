import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Board from './pages/Board';
import Signup from './pages/Signup';
import Login from './pages/Login';
import ReportByDates from './pages/ReportByDates';
import ProtectedRoute from './components/ProtectedRoute';
import EditBoard from './components/Boards/EditBoard';
import PasswordReset from './pages/PasswordReset';
import PasswordResetRequest from './pages/PasswordResetRequest';
import ActivateAccount from './pages/ActivateAccount';
import EditCategoriesInputOutput from './pages/EditCategoriesInputOutput';
import ReportByWeeks from './pages/ReportByWeeks';

import './App.css';

function App() {
  return (
    <Router>
      <Routes>
        {/* Rutas protegidas que incluyen el layout */}
        <Route
          path="/"
          element={
            <ProtectedRoute
              element={
                <div id="root" className="bg-custom">
                  <Header />
                  <div className="container">
                    <Home />
                  </div>
                  <Footer />
                </div>
              }
            />
          }
        />
        <Route
          path="/Board"
          element={
            <ProtectedRoute
              element={
                <div id="root" className="bg-custom">
                  <Header />
                  <div className="container">
                    <Board />
                  </div>
                  <Footer />
                </div>
              }
            />
          }
        />
        <Route
          path="/ReportByDates"
          element={
            <ProtectedRoute
              element={
                <div id="root" className="bg-custom">
                  <Header />
                  <div className="container">
                    <ReportByDates />
                  </div>
                  <Footer />
                </div>
              }
            />
          }
        />
        <Route
          path="/EditBoard"
          element={
            <ProtectedRoute
              element={
                <div id="root" className="bg-custom">
                  <Header />
                  <div className="container">
                    <EditBoard />
                  </div>
                  <Footer />
                </div>
              }
            />
          }
        />

        <Route
          path="/EditCategoriesInputOutput"
          element={
            <ProtectedRoute
              element={
                <div id="root" className="bg-custom">
                  <Header />
                  <div className="container">
                    <EditCategoriesInputOutput />
                  </div>
                  <Footer />
                </div>
              }
            />
          }
        />


        <Route
          path="/ReportByWeeks"
          element={
            <ProtectedRoute
              element={
                <div id="root" className="bg-custom">
                  <Header />
                  <div className="container">
                    <ReportByWeeks />
                  </div>
                  <Footer />
                </div>
              }
            />
          }
        />


        {/* Rutas sin protección, accesibles sin token */}
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/PasswordReset" element={<PasswordReset />} />
        <Route path="/PasswordResetRequest" element={<PasswordResetRequest />} />
        <Route path="/ActivateAccount" element={<ActivateAccount />} />
        
      </Routes>
    </Router>
  );
}

export default App;