import { Row, Col, Button, Card } from 'react-bootstrap';
import { useState, useEffect }  from 'react';
import { getCurrentWeekInputOutput } from '../../services/reports';
import { getFormattedAmount_moneyBySessionSetting } from '../../utils/currenciesUtils';
import Loading from '../../components/Loading';

function WeekReport({ week, setSelectedWeek})
{    
    const  board_id = sessionStorage.getItem('board_id');
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState({
        totalAmountIncome: 0,
        totalAmountExpenses:0 ,
        startDate: '',
        endDate: ''
    })
    const handleSelect = () => {
        setSelectedWeek(week)
    }
    useEffect(() => {
        getCurrentWeekInputOutput(board_id, week).then((result) => {
            setData(result);
            setIsLoading(false)
        }).catch((error) => {            
        })
    },[])
    return (
        <>
        {
            isLoading ? 
            <Loading /> : 
            <Row className='mt-4'>
                <Col>
                    <Card onClick={handleSelect} >
                        <Card.Body>
                            <Card.Title>{data.startDate} - {data.endDate}</Card.Title>          
                            <h3 className='text-success'>Ingresos: { getFormattedAmount_moneyBySessionSetting(data.totalAmountIncome) }</h3>              
                            <h3 className='text-danger'>Gastos: { getFormattedAmount_moneyBySessionSetting(data.totalAmountExpenses) }</h3>        
                                
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        }
        
        </>
    )
}

export default WeekReport;