import Loading from '../Loading';
import React, { useEffect, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { getFormattedAmount_moneyBySessionSetting } from '../../utils/currenciesUtils';
import { getTransactionById , deleteTransaction } from '../../services/transactions';

function TransactionModalDisplay({ id }) {
    const [money, setMoney] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [date, setDate] = useState("");
    const [category_output, setCategory_output] = useState(null);
    const [movementType, setMovementType] = useState("");
    const [comments, setComments] = useState("");
    useEffect(() => {
        if(id != 0)
        {
          getTransactionById(id).then((transaction) => {
            setMoney(transaction.amount_money);
            setDate(transaction.formatedDate);
            setMovementType(transaction.transactions_movement_type.name)
            if(transaction && transaction.transaction_output_category)
            {
              setCategory_output(transaction.transaction_output_category);
            }
            setComments(transaction.comments);
            setIsLoading(false);
          }).catch(()=>{
            setIsLoading(false);
          })
        }
      
      }, [id])
    return (
        isLoading?
          <Loading /> :
          <ListGroup variant="flush">
                    <ListGroup.Item>{money ? getFormattedAmount_moneyBySessionSetting(money) : null}</ListGroup.Item>
                    <ListGroup.Item>{date}</ListGroup.Item>
                    <ListGroup.Item>{movementType}</ListGroup.Item>
                    {
                        category_output ?
                        <ListGroup.Item> <i class={category_output.icon}></i>   {category_output.name}</ListGroup.Item> :
                        null
                    }
                    {
                      comments ? 
                      <ListGroup.Item>Comentarios: {comments}</ListGroup.Item>:
                      null
                    }
              </ListGroup>
    )
}


export default TransactionModalDisplay;