import Card from 'react-bootstrap/Card';
import { useState }  from 'react';
import { useNavigate } from 'react-router-dom';
import { getFormattedAmount_money } from '../../utils/currenciesUtils';

function BoardHome({ board }) {
  const navigate = useNavigate();
  const [boardId, setBoardId] = useState(board.id);
  const handleRediect = () => {
    sessionStorage.setItem('board_id', boardId);
    navigate(`/board`)
  }
  return (
    <Card className='mt-2 mb-2' onClick={handleRediect} >   
   
      <Card.Body>
        <Card.Title><h2>{board.name}</h2></Card.Title>
        <Card.Subtitle className="mb-2">
        <h3>Total:&nbsp;
        {
          getFormattedAmount_money(board.totalAmount, board.board_currency.name_short, board.board_currency.local)        
        }&nbsp;
       <small class="text-muted">{board.board_currency.name_short}</small>
        </h3>
        </Card.Subtitle>        
      </Card.Body>
    </Card>
  );
}

export default BoardHome;

