import Spinner from 'react-bootstrap/Spinner';

function Loading() {
    return (
        <div className="row justify-content-center">
            <div className="col-xl-12 text-center">
                <Spinner animation="grow" />
                <div><b>Cargando...</b></div>
            </div>
        </div>
    )
}

export default Loading;