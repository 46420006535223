import { useState, useEffect, useRef }  from 'react';
import { Row, Col, Button, Offcanvas, Form, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import WeekReport from '../components/Reports/WeekReport';
import { getSomeWeeks} from '../services/reports';
import { ToastContainer, toast } from 'react-toastify';
import TransactionsByWeekModal from '../components/Reports/TransactionsByWeekModal';

function ReportByWeeks() {
    const navigate = useNavigate();
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const [weeks, setWeeks] = useState([0,1,2,3]);
    const [availableWeeks, setAvailableWeeks] = useState([]);
    const [weekQty, setWeekQty] = useState(10);    
    const [selectedWeek, setSelectedWeek] = useState(null)
    const [showTransactions, setShowTransactions] = useState(false)
    
    const weeksContainerRef = useRef(null); // Referencia para el contenedor de semanas



    const handleMoreWeeks = () => {
        setWeekQty(prevWeekQty => prevWeekQty + 10);
        toast.success("Se cargaron más semanas")
    };

    const handleBack = () => {
        navigate(-1);
    };

    const handleToggleOffcanvas = () => {
        setShowOffcanvas(!showOffcanvas);
    };

    const handleCheckboxChange = (offset) => {
        setWeeks(prevWeeks => {
            if (prevWeeks.includes(offset)) {
                return prevWeeks.filter(weekOffset => weekOffset !== offset);
            } else {
                return [...prevWeeks, offset];
            }
        });
    };
    useEffect(() => {
        if (selectedWeek !== null) {
            setShowTransactions(true);
        }
    }, [selectedWeek]);

    useEffect(() => {
        getSomeWeeks(weekQty).then((data) => {
            setAvailableWeeks(data);
        });
    }, [weekQty]);

    useEffect(() => {
        if (weeksContainerRef.current) {
            // Desplazar hacia abajo cuando se carguen más semanas
            weeksContainerRef.current.scrollTop = weeksContainerRef.current.scrollHeight;
        }
    }, [availableWeeks]); // Hacer scroll cuando availableWeeks cambie

    return (
        <>
            <Row className='mt-2'>
                <Col xs={2} className='text-left'>
                    <Button className='mt-1'  size='sm' variant="dark" onClick={handleBack} ><i className="bi bi-arrow-left"></i></Button>
                </Col>
                <Col xs={8} className='text-center'>
                    <h4>
                        Reporte por semanas
                    </h4>                </Col>
                <Col xs={2} className='text-end'>
                    <Button className='mt-1' size='sm' variant="outline-secondary" onClick={handleToggleOffcanvas}><i className="bi bi-filter"></i> </Button>
                </Col>
            </Row>
            {
                (weeks && weeks.length > 0) ?
                weeks.map((weekOffset) => (
                    <WeekReport setSelectedWeek={setSelectedWeek} key={weekOffset} week={weekOffset} />
                )) :
                null
            }

            <Offcanvas show={showOffcanvas} onHide={handleToggleOffcanvas} placement='end'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Seleccionar semanas</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Row 
                        ref={weeksContainerRef} // Añadir la referencia al contenedor de semanas
                        style={{ maxHeight: '650px', overflowY: 'auto' }}
                    >
                        <Col>
                            <Form>
                            {availableWeeks.map(week => (
                                <Form.Check
                                    key={week.offset}
                                    type="checkbox"
                                    id={`week-${week.offset}`}
                                    label={`${week.startDatell} - ${week.endDatell}`}
                                    checked={weeks.includes(week.offset)}
                                    onChange={() => handleCheckboxChange(week.offset)}
                                />
                            ))}
                            </Form>
                        </Col>
                    </Row>
                    <Row className='mt-4'>
                        <Col className='text-center'>
                            <Button size='sm' variant="outline-secondary" onClick={handleMoreWeeks}>Más semanas +</Button>
                        </Col>
                    </Row>

                   
                </Offcanvas.Body>
            </Offcanvas>
            <ToastContainer />
            <TransactionsByWeekModal showTransactions={showTransactions} setShowTransactions={setShowTransactions} selectedWeek={selectedWeek} />            
        </>
    );
}

export default ReportByWeeks;