
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Row, Col, Button, Card } from 'react-bootstrap';
import Input from '../components/Boards/Input';
import Output from '../components/Boards/Output';
import TransactionsList from '../components/Transactions/TransactionsList';
import { getTotalAmountByBoard_id, getLastTransactions } from '../services/transactions';
import { getBoardById } from '../services/boards'
import Loading from '../components/Loading';
import { getFormattedAmount_moneyBySessionSetting } from '../utils/currenciesUtils';
import './Board.css';  


function Board() {
  const navigate = useNavigate();
  const [reload, setReload] = useState(false)
  const  id = sessionStorage.getItem('board_id');
  const [totalAmount, setTotalAmount] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [boardName, setBoardName] = useState([]);
  const [transactionType, setTransactionType] = useState('')
  const [isLoadingTotalAmount, setIsLoadingTotalAmount] = useState(true);
  const [isLoadingTransactions, setIsLoadingTransactions] = useState(true);
  const handleInput = () => {
    setTransactionType('Input');
  }
  const handleOutput = () => {
    setTransactionType('Output');
  }
  const handleRedirectToReports = () => {
    navigate(`/ReportByDates`)
  }
  const handleRedirectToWeeklyReport = () => {
    navigate(`/ReportByWeeks`)
  }
  const handleBack = () =>{
    navigate(-1)
  }
  const handleEditBoard = () => {
    navigate(`/EditBoard`)
  }
  useEffect(()=> {
    getBoardById(id).then((board) => {
        setBoardName(board.name) 
        sessionStorage.setItem('currency', board.board_currency.name_short);
        sessionStorage.setItem('local', board.board_currency.local);
    })
    getTotalAmountByBoard_id(id).then((result)=> {
        setIsLoadingTotalAmount(false);
        setTotalAmount(result.totalAmount);
    });
    getLastTransactions(id).then((list) => {
        setTransactions(list);
        setIsLoadingTransactions(false)
    });
  },[])
  useEffect(()=> {
    getTotalAmountByBoard_id(id).then((result)=> {
        setTotalAmount(result.totalAmount);
    })
    getLastTransactions(id).then((list) => {
        setTransactions(list);
    }); 
  },[reload])
  return (
    <>
        <Row className='mt-2'>
                    <Col xs={6} className='text-left'>
                        <Button className='m-1' size='sm' variant="dark" onClick={handleBack} ><i class="bi bi-arrow-left"></i></Button>
                    </Col>
                    <Col xs={6} className='text-end'>
                    <Button className='m-1' size='sm' variant="dark" onClick={handleRedirectToWeeklyReport} >Semanal <i class="bi bi-clipboard2-data-fill"></i></Button>
                        <Button className='m-1' size='sm' variant="dark" onClick={handleRedirectToReports} ><i class="bi bi-clipboard2-data-fill"></i></Button>
                        <Button className='m-1' size='sm' variant="dark" onClick={handleEditBoard}><i class="bi bi-gear"></i></Button>
                    </Col>
        </Row>
        <Row className="mt-3 mb-3">
            <Col>
            <Card>
                <Card.Body>
                <h2>{boardName}</h2>
                {
                isLoadingTotalAmount ? (
                <Loading />
                ) : (
                <h3 className='responsive-text'>Total: {totalAmount ? getFormattedAmount_moneyBySessionSetting(totalAmount) : getFormattedAmount_moneyBySessionSetting(0)}</h3>
                )}
               
                    <Row>
                        <Col xs={12} className="d-flex align-items-center">
                            <Button variant="outline-success" onClick={handleInput} className="me-2">
                            <i className="bi bi-currency-dollar"></i> Ingreso <i className="bi bi-plus"></i>
                            </Button>
                            <Button variant="outline-danger" onClick={handleOutput}>
                            <i className="bi bi-currency-dollar"></i> Gasto <i className="bi bi-dash"></i>
                            </Button>
                        </Col>
                    </Row>
                    <Row  className='mt-3' >
                        <Col xl={12}>
                            {
                                transactionType == "" ? 
                                null : 
                                (transactionType == "Output" ?
                                <Output board_id={id} reload={reload} setReload={setReload}  /> :
                                <Input board_id={id}  reload={reload} setReload={setReload} />)
                            }
                        </Col>
                    </Row>
                    {
            isLoadingTransactions ? 
            <Loading /> :
            <Row className='mt-5' >
                <Col xl={12}>
                    <TransactionsList data={transactions} reload={reload} setReload={setReload} />
                </Col>
            </Row>
        }
                </Card.Body>
            </Card>
            </Col>
        </Row>

       
      
      
        
    </>
  );
}

export default Board;