import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { getCurrencies } from '../../services/generalQueries';

function AddBoardModal({ show, handleClose, handleSave }) {
  const [inputValue, setInputValue] = useState('');
  const [currencies, setCurrencies] = useState([]);
  const [currencyId, setCurrencyId] = useState(0);
  const onSave = () => {
    if(inputValue && currencyId)
    {
        handleSave({inputValue, currencyId });
        handleClose();
    }
    // Cierra el modal después de guardar
  };
  const handleCurrencyChange = (e) => {
    const { name, value } = e.target;
    setCurrencyId(parseInt(value))
  }
  useEffect(() => {
    getCurrencies().then((list) => {
        setCurrencies(list);
    })
  }, [])
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Nueva cuenta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Nombre:</Form.Label>
            <Form.Control
              type="text"
              autoFocus
              placeholder=""
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </Form.Group>
          <Form.Group className='mt-2' controlId="formRole">
                            <Form.Label>Divisa:</Form.Label>
                            <Form.Control
                                as="select"
                                name="categoryId"
                                value={currencyId}
                                onChange={handleCurrencyChange}
                            >
                                <option key={0} value={0}>Seleccionar</option>
                                {
                                currencies && currencies.length ?   
                                currencies.map((currency) => (
                                        <option key={currency.id} value={currency.id}>{currency.name_short} - {currency.name_long}</option>
                                ))
                                :
                                null
                                }
                            </Form.Control>
                        </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={onSave}>
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddBoardModal;