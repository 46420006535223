import Loading from '../Loading';
import React, { useEffect, useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { getTransactionById , deleteTransaction } from '../../services/transactions';
import { getInputCategorires, getOutputCategories } from '../../services/generalQueries';


function TransactionModalEdit({ id, data, setData }) {
    const [categories, setCategories] = useState([]);
    const [outputCategories, setOutputCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const handleChange = (e) => {
        const { value, name } = e.target;
        setData({
            ...data,
            [name]: value
            });    
    }
    const handleAmountMoneyChange = (e) => {
        const { value, name } = e.target;
        setData({
                ...data,
                [name]: value
                });          
      };
    useEffect(() => {
        if(id != 0)
        {
          getTransactionById(id).then((transaction) => {            
            setData(transaction);
            setIsLoading(false);
          }).catch(()=>{
            setIsLoading(false);
          })
          getInputCategorires().then((categoriesList) => {
            setCategories(categoriesList);
          })
          getOutputCategories().then((outputCategoriesList) => {
            setOutputCategories(outputCategoriesList)
          })
        }
      
      }, [id])
    return (
        isLoading?
        <Loading /> :
        <>
        <Row  >
            <Col xl={6}  >
                <Form.Group className='mb-4'  controlId="exampleForm.ControlInput1">
                    <Form.Label>{data.is_input ? "Ingreso " : "Gasto "} ($)</Form.Label>
                    <Form.Control 
                    type="text" 
                    name="formattedAmount_money"
                    placeholder="Nombre de la cuenta" 
                    value={data.formattedAmount_money}
                    onChange={handleAmountMoneyChange}
                    />
                </Form.Group>
            </Col>
            <Col xl={6} >
                <Form.Group className='mb-4' controlId="formDateTime">
                <Form.Label>Fecha</Form.Label>
                    <Form.Control
                        type="date"
                        name='formattedDateForInput'
                        value={data.formattedDateForInput}                 
                        className="mx-auto" 
                        onChange={handleChange}
                    />
                </Form.Group>
            </Col>
        </Row> 
    
         <Row >
            <Col xl={6} >
                <Form.Group className='mb-4' controlId="formRole">
                    <Form.Label>{data.is_input ? "En donde se obtuvo el ingreso" : "De donde salio el gasto "}</Form.Label>
                    <Form.Control
                        as="select"
                        name="movement_type_id"
                        onChange={handleChange}
                        value={data.movement_type_id}
                        >
                        <option key={0} value={0}>Seleccionar</option>
                        {
                        categories && categories.length ?   
                        categories.map((category) => (
                                <option key={category.id} value={category.id}>{category.name}</option>
                        ))
                        :
                        null
                        }
                    </Form.Control>
                </Form.Group>
            </Col>
            <Col xl={6} >
                {
                    data.is_input ? 
                    <Form.Group className='mb-4' controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Comentarios</Form.Label>
                        <Form.Control 
                            as="textarea" 
                            rows={2} 
                            value={data.comments} 
                            placeholder="Comentarios"
                            name='comments'
                            onChange={handleChange}
                        />
                    </Form.Group> : 
                    <Form.Group className='mb-4'  controlId="formRole">
                          <Form.Label>Tipo de gasto</Form.Label>
                        <Form.Control 
                            as="select"
                            name="output_category_id"
                            onChange={handleChange}
                            value={data.output_category_id}
                            >
                            <option key={0} value={0}>Seleccionar</option>
                            {
                            outputCategories && outputCategories.length ?   
                            outputCategories.map((category) => (
                                    <option key={category.id} value={category.id}>{category.name}</option>
                            ))
                            :
                            null
                            }
                        </Form.Control>
                    </Form.Group>
                }
               
            </Col>
        </Row>
        {
            data.is_input ?
            null :
            <Row >
                <Col>
                <Form.Group className='mb-4' controlId="exampleForm.ControlTextarea1">
                <Form.Label>Comentarios</Form.Label>
                <Form.Control 
                    as="textarea" 
                    rows={2} 
                    name='comments'
                    onChange={handleChange}
                    value={data.comments} 
                    placeholder="Comentarios"
                />
            </Form.Group>
                </Col>
            </Row>
        }
        </>
    )
}


export default TransactionModalEdit;