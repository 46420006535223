import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getFormattedAmount_moneyBySessionSetting } from '../../utils/currenciesUtils';
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

// Función para generar colores aleatorios
const generateRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const DoughnutChart = ({ data }) => {
  let chartData = {};
  if (data && data.length > 0) {
    chartData = {
      labels: data.map(item => item.categoryName),
      datasets: [
        {
          label: 'Total Amount',
          data: data.map(item => Math.abs(item.totalAmount)),
          backgroundColor: data.map(() => generateRandomColor()),
          borderColor: data.map(() => '#ffffff'),
          borderWidth: 1,
        },
      ],
    };
  }   

  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      datalabels: {
        formatter: (value, context) => {
          // Formatear el valor como moneda
          return getFormattedAmount_moneyBySessionSetting(value);
        },
        color: '#fff',
        font: {
          weight: 'bold',
          size: 14,
        },
      },
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            // Formatear el valor del tooltip como moneda
            return getFormattedAmount_moneyBySessionSetting(tooltipItem.formattedValue);
          },
        },
      },
    },
  };

  return (
    <>
      {data && data.length > 0 ? (

            <div className='mt-3' style={{ height: '100%',  height: '300px'  }}>
              <Doughnut data={chartData} options={chartOptions} />
            </div>
       
      ) : null}
    </>
  );
};

export default DoughnutChart;