import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import './Login.css'; 
import { activateAccount } from '../services/auth';  // Importa tu función que hace la llamada a la API

function ActivateAccount() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const iv = queryParams.get('iv');
  const content = queryParams.get('content');
  const [activationResult, setActivationResult] = useState(null); // null significa que aún estamos cargando

  useEffect(() => {
    // Llama a la API para activar la cuenta
    activateAccount(iv,content).then((response) => {
      setActivationResult(response.result); // Asume que response.result es true o false
    }).catch(() => {
      setActivationResult(false); // En caso de error, asume que la activación falló
    });
  }, []);

  return (
    <>
    {activationResult === null ? (
      // Mostrar un spinner mientras se carga la activación
      <Container className="d-flex justify-content-center align-items-center mt-5 min-vh-100">
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Activando cuenta...</span>
          </Spinner>
          <h2 className="mt-3">Activando cuenta...</h2>
        </div>
      </Container>
    ) : activationResult ? (
      // Mostrar un mensaje de éxito si la activación fue exitosa
      <Container className="d-flex justify-content-center align-items-center mt-5 min-vh-100">
        <div className="text-center">
          <h1>GastoHormiga.mx</h1>
          <h2 className="mt-3">¡Su cuenta ha sido activada exitosamente!</h2>
          <a href="/">Volver al inicio</a>
        </div>
      </Container>
    ) : (
      // Mostrar un mensaje de error si la activación falló
      <Container className="d-flex justify-content-center align-items-center mt-5 min-vh-100">
        <div className="text-center">
          <h1>GastoHormiga.mx</h1>
          <h2 className="mt-3">El enlace de activación no es válido o ha caducado.</h2>
          <a href="/">Volver al inicio</a>
        </div>
      </Container>
    )}
    </>
  );
}

export default ActivateAccount;