import React, { useState, useEffect }  from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import AddBoardModal from '../components/Boards/AddBoardModal';
import BoardHome from '../components/Boards/BoardHome';
import Loading from '../components/Loading';
import { addBoard, getActiveBoardsByUserId } from '../services/boards';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';


function Home() {
  const navigate = useNavigate();
  const [realoadList, setReloadList] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [boards, setBoards] = useState([]);
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleSaveData = (data) => {
    addBoard(data.inputValue, data.currencyId).then((addBoardResult) => {
        if(addBoardResult.result)
        {
            toast.success(`Cuenta creada correctamente`)
            setReloadList(true)
        }
        else
        {
            toast.warning(`!Algo salio mal!`);
        }
    })

  };
  const handleEditCategories = () => {
    navigate(`/EditCategoriesInputOutput`);
  }
  useEffect(() => {
    getActiveBoardsByUserId().then((list) => {
        setBoards(list)
        setIsLoading(false)
    })
  },[])
  useEffect(() => {
    setIsLoading(true)
    getActiveBoardsByUserId().then((list) => {
        setBoards(list)
        setIsLoading(false)
    })
  },[realoadList])

  return (
    <>
        <Row className='pt-3'>
            <Col xs={2}>
                <Button variant="outline-dark" onClick={handleOpenModal} >+</Button>
            </Col>
            <Col xs={8}>
                <h1>Mis cuentas</h1>
            </Col>
            <Col xs={2} >
                <Button className='m-1' size='sm' variant="dark" onClick={handleEditCategories}><i class="bi bi-gear"></i></Button>
            </Col>
        </Row>
        <Row className='pt-3'>
            <Col>
                {
                    isLoading ? 
                    <Loading/> :
                    (
                        boards && boards.length ?
                        boards.map((board) => (
                            <BoardHome  board={board} />
                        ))
                        :
                        null
                    )
                }
            </Col>
        </Row>
        <AddBoardModal show={showModal} handleClose={handleCloseModal} handleSave={handleSaveData} />
        <ToastContainer />
     </>
  );
}

export default Home;