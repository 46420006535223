


export const getFormattedAmount_money= (amount_money, currency, local) => {
    const setting = { style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 0 };
    if(amount_money)
   {
        try {
            return  amount_money.toLocaleString(local,setting) 
        } catch (error) {
            return null;
        }        
   }
   else
   {
    let zeroValue = 0;
    return  zeroValue.toLocaleString(local,setting) 
   }
};

export const getCurrencyBySessionSetting = () => {
    return sessionStorage.getItem('currency');
}

export const getFormattedAmount_moneyBySessionSetting = (amount_money) => {
    let currency = sessionStorage.getItem('currency');
    let local = sessionStorage.getItem('local');             
    const setting = { style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 0 };
    if(amount_money)
    {
         try {
             return amount_money.toLocaleString(local,setting)
         } catch (error) {
             return null;
         }        
    }
    else
    {
      let zeroValue = 0;
      return zeroValue.toLocaleString(local,setting)
      return null;
    }
 };
